.container {
  @include min-screen($breakpoint_pc) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: $wrap_pc;
    padding-top: 0;
  }
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin: {
      right: auto;
      left: auto;
    }
  }
  @include max-screen($breakpoint_pc - 1) {
    width: 100%;
  }
  &--index {
    padding-top: 6rem;
    @include min-screen($breakpoint_pc) {
      padding-top: 2rem;
    }
  }
}
.main {
  width: 100%;
  margin: {
    right: auto;
    left: auto;
  }
  @include min-screen($breakpoint_pc) {
    width: $wrap_left;
  }
  @include screen($breakpoint_sp, $breakpoint_pc) {
    width: 100%;
  }
}
.aside {
  width: $wrap_sp;
  margin: {
    right: auto;
    left: auto;
  }
  @include min-screen($breakpoint_pc) {
    width: $wrap_right;
  }
  @include screen($breakpoint_sp, $breakpoint_pc) {
    width: 100%;
  }
}
.inner {
  width: 100%;
}
.title {
  background: $main_color;
  border-left: solid 8px $key_color;
  color: #fefefe;
  margin-bottom: 1rem;
  padding: 0.6rem 1rem;
  &--recommend {
    @extend .title;
    background: $key_color;
    border-left: solid 8px $main_color;
  }
}
.article {
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  @include min-screen($breakpoint_pc) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__item {
    position: relative;
    margin-bottom: 2rem;
    padding: 0.5rem;
    background: $bg_postColor;
    box-shadow: 0 2px 1px #efefef;
    @include min-screen($breakpoint_pc) {
      width: 49%;
      margin-bottom: 1rem;
      padding: 1rem;
      box-shadow: 0 0 10px 0px #e3e3e3;
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    color: $main_color;
    text-decoration: none;
  }
  &__title {
    margin-bottom: 1rem;
    padding-bottom: 0;
    font-weight: bold;
    @include min-screen($breakpoint_pc) {
      margin-bottom: 0;
      padding-bottom: 2rem;
    }
  }
  &__info {
    text-align: right;
    @include min-screen($breakpoint_pc) {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
    }
    & span {
      display: inline-block;
      @include fontsize(12);
    }
    &-category {
      padding: {
        right: 0.4rem;
        left: 0.4rem;
      }
      background: $main_color;
      color: $bg_color;
    }
    &-time {
      @include fontsize(12);
      &:before {
        content: "\f017";
        font-family: "Font Awesome 5 Free";
      }
    }
    &-modified {
      @include fontsize(12);
      &:before {
        content: "\f2f1";
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
      }
    }
  }
  .no-post {
    margin-bottom: 2rem;
    text-align: left;
  }
}
.primary {
  padding: {
    right: 0.25rem;
    left: 0.25rem;
  }
}
