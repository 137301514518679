.search {
  position: relative;
  width: 100%;
  margin-bottom: 0.4rem;
  & #s {
    width: 100%;
    padding: 0.8rem 2.5rem 0.8rem 0.8rem;
    @include fontsize(16);
    border: solid 1px #ccc;
    &:focus {
      border: solid 2px #ffd1a3;
    }
  }
  & #search-submit {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
  }
}
