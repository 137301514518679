//////////////////// global-nav ////////////////////
.drawer {
  position: relative;
  @include min-screen($breakpoint_pc) {
    position: static;
    width: $wrap_pc;
    margin: {
      right: auto;
      left: auto;
    }
  }
  // inputのcheckboxは非表示
  &__checkbox {
    display: none;
  }
  // ハンバーガーアイコン
  &__icon {
    cursor: pointer;
    display: block;
    position: fixed;
    top: 1.5rem;
    right: 0;
    width: 56px;
    @include min-screen($breakpoint_pc) {
      display: none;
    }
    // ハンバーガーアイコンの中の線
    &-parts,
    &-parts:before,
    &-parts:after {
      background-color: #fff;
      display: block;
      width: 26px;
      height: 2px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
    &-parts:before,
    &-parts:after {
      content: " ";
    }
    &-parts:before {
      top: 16px;
    }
    &-parts:after {
      top: -16px;
    }
  }
  // ドロワーメニュー
  &__menu {
    position: fixed;
    top: 0;
    right: -100%;
    max-width: 100%;
    width: 100%;
    height: 100vh;
    padding: 2.8rem 1rem 1rem;
    background: $bg_color;
    color: $main_color;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 9000;
    @include min-screen($breakpoint_pc) {
      position: static;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      height: auto;
      padding: 0.5rem;
    }
  }
  // ドロワーメニュー開いた時のメインエリアを暗く
  &__overlay {
    background: #fff;
    opacity: 0;
    pointer-events: none; //ポインタの動作全部無効化
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
  }
  // z-indexの指定（1がメインエリア）
  &__icon {
    z-index: 9999;
  }
  &__menu {
    z-index: 8000;
  }
  &__overlay {
    z-index: 2;
  }
  // チェックが入ったら（アイコンクリックしたら）
  &__checkbox:checked ~ {
    // ハンバーガーアイコン
    .drawer__icon {
      .drawer__icon-parts {
        background: transparent;
      }
      .drawer__icon-parts:before {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 0;
        background: $main_color;
      }
      .drawer__icon-parts:after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0;
        background: $main_color;
      }
    }
    // メインエリアを暗くする
    .drawer__overlay {
      opacity: 0.3;
      pointer-events: auto;
    }
    // メニューをだす
    .drawer__menu {
      right: 0;
    }
  }
  // 動きをスムーズに
  &__icon-parts,
  &__icon-parts:after,
  &__icon-parts:before,
  &__overlay,
  &__menu {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
}
////////////////////
// global-nav
////////////////////
.global-nav {
  @include min-screen($breakpoint_pc) {
    display: flex;
    flex-wrap: nowrap;
  }
  & li {
    background: $bg_color;
    a {
      display: block;
      padding: {
        top: 1rem;
        bottom: 1rem;
      }
      color: $main_color;
      text-decoration: none;
      border-bottom: solid 1px #999;
      @include min-screen($breakpoint_pc) {
        padding: {
          right: 1.5rem;
          left: 1.5rem;
        }
        border-bottom: none;
        transition: 0.2s;
      }
      &:hover {
        @include min-screen($breakpoint_pc) {
          color: $sub_color;
          transform: rotate(-5deg);
        }
      }
    }
  }
}
.icon {
  & a::before {
    margin-right: 0.4rem;
  }
  &--home a::before {
    content: "\f015";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
  &--web a::before {
    content: "\f07b";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
  &--about a::before {
    content: "\f406";
    font: {
      family: "Font Awesome 5 Free";
      weight: 900;
    }
  }
}
.sub-menu {
  @include min-screen($breakpoint_pc) {
    display: none;
  }
}

//////////////////// footer-nav ////////////////////
.footer-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin: {
      right: auto;
      bottom: 0.5rem;
      left: auto;
    }
  }
  & li {
    &:not(:last-child) {
      margin-right: 0.5rem;
      @include min-screen($breakpoint_pc) {
        margin-right: 1rem;
      }
    }
    & a {
      display: inline-block;
      color: #fff;
      @include fontsize(12);
      text-decoration: none;
      @include min-screen($breakpoint_pc) {
        transition: 0.2s;
      }
      &:hover {
        @include min-screen($breakpoint_pc) {
          color: $sub_color;
          transform: rotate(-5deg);
        }
      }
    }
  }
}
//////////////////// 背景のHTML固定 ////////////////////
.scroll-prevent {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
