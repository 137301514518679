.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  background: $main_color;
  border-bottom: solid 4px $key_color;
  z-index: 8000;
  @include min-screen($breakpoint_pc) {
    position: relative;
    padding: 1rem 0.5rem;
  }
  &__logo {
    color: $bg_color;
    width: 150px;
    height: auto;
    line-height: 0;
    @include min-screen($breakpoint_pc) {
      width: 200px;
      margin: {
        right: auto;
        left: auto;
      }
    }
  }
  &__description {
    color: $bg_color;
    @include fontsize(12);
    @include min-screen($breakpoint_pc) {
      text-align: center;
    }
  }
}
