html {
  @include fontsize(16);
}
body {
  position: relative;
  background: $bg_color;
  color: $main_color;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic",
    YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
  line-height: 1.5;
  letter-spacing: 0.04rem;
  counter-reset: post_rank;
}
a {
  color: #3939ff;
}
.page-scroll {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  width: 36px;
  height: 36px;
  background: $key_color;
  border-radius: 50%;
  & a {
    display: block;
    width: 100%;
    height: 100%;
    color: $bg_color;
    text-align: center;
    border-radius: 50%;
    & i {
      line-height: 36px;
    }
  }
}
