.recommend {
  width: $wrap_sp;
  margin: {
    top: 6rem;
    right: auto;
    bottom: 2rem;
    left: auto;
  }
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin: {
      top: 0;
      right: auto;
      left: auto;
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__item {
      width: calc(100% / 2 - 0.5rem);
      margin-bottom: 2rem;
      @include min-screen($breakpoint_pc) {
        width: calc(100% / 4 - 0.5rem);
        padding: 0.5rem;
        background: $bg_postColor;
      }
    }
    &__link {
      color: $main_color;
      text-decoration: none;
    }
    &__title {
      @include fontsize(12);
      font-weight: bold;
      @include min-screen($breakpoint_pc) {
        @include fontsize(16);
      }
    }
    &__category {
      @extend .article__info-category;
      display: inline-block;
      @include fontsize(12);
    }
    &__time {
      @extend .article__info-time;
      display: inline-block;
      @include fontsize(12);
    }
    &__modified {
      @extend .article__info-modified;
      display: inline-block;
      @include fontsize(12);
    }
  }
}
