.bread-crumb {
  display: flex;
  flex-wrap: wrap;
  width: $wrap_sp;
  margin: {
    right: auto;
    bottom: 0.6rem;
    left: auto;
  }
  padding-top: 5rem;
  @include min-screen($breakpoint_pc) {
    width: $wrap_pc;
    margin-bottom: 1rem;
    padding-top: 0;
  }
  &__item {
    @include fontsize(12);
    &::before {
      display: inline-block;
      margin-right: 0.1rem;
      color: rgba($main_color, 0.5);
      font-family: "Font Awesome 5 Free";
    }
    &::after {
      content: "\0bb";
      display: inline-block;
      margin: {
        right: 0.5rem;
        left: 0.5rem;
      }
    }
    &:last-child::after {
      content: "";
    }
    & span {
      color: rgba($main_color, 0.5);
    }
    & a {
      color: rgba($main_color, 0.5);
      text-decoration: none;
    }
    &--home {
      color: rgba($main_color, 0.5);
      &::before {
        content: "\f015";
        font-weight: 900;
      }
    }
    &--category::before {
      content: "\f07b";
      font-weight: 900;
    }
    &--search::before {
      content: "\f002";
      font-weight: 900;
    }
  }
}
