// .recommend {
//   width: $wrap_sp;
//   margin: {
//     top: 6rem;
//     right: auto;
//     bottom: 3rem;
//     left: auto;
//   }
//   @include min-screen($breakpoint_pc) {
//     width: $wrap_pc;
//     margin-top: 0;
//   }
//   & .article__title {
//     @include min-screen($breakpoint_pc) {
//       min-height: 90px;
//     }
//   }
// }
.article__slider {
  margin: 0;
  padding: 0;
  /*fix flickering when used background-image instead of <img> (on Chrome)*/
  perspective: 1000;
}
ul.article__slider {
  list-style: none;
}
.bx-viewport {
  /* fix other elements on the page moving (in Chrome) */
  height: auto !important;
  transform: translateZ(0);
}
/** THEME
  ===================================*/
.bx-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  padding: {
    top: 1rem;
    right: 1rem;
    left: 1rem;
  }
  border: solid 1px #e2e2e2;
  touch-action: pan-y;
  & img {
    max-width: 100%;
    display: block;
  }
  & .bx-pager,
  & .bx-controls-auto {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
  }
  /* LOADER */
  & .bx-loading {
    min-height: 50px;
    background: url("./images/common/bx_loader.gif") center center no-repeat
      #ffffff;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
  }
  /* PAGER */
  & .bx-pager {
    text-align: center;
    font-size: 0.85em;
    font-family: Arial;
    font-weight: bold;
    color: #666;
    padding-top: 20px;
  }
  & .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border-radius: 5px;
    &:hover,
    &.active,
    &:focus {
      background: #000;
    }
  }
  & .bx-pager-item,
  & .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    vertical-align: bottom;
    *zoom: 1;
    *display: inline;
  }
  & .bx-pager-item {
    font-size: 0;
    line-height: 0;
  }
  /* DIRECTION CONTROLS (NEXT / PREV) */
  & .bx-prev {
    left: -0.6rem;
    background: url("./images/common/controls.png") no-repeat 0 -32px;
    &:hover,
    &:focus {
      background-position: 0 0;
    }
  }
  & .bx-next {
    right: -0.2rem;
    background: url("./images/common/controls.png") no-repeat -43px -32px;
    &:hover,
    &:focus {
      background-position: -43px 0;
    }
  }
  & .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    z-index: 7000;
    &.disabled {
      display: none;
    }
  }
  /* AUTO CONTROLS (START / STOP) */
  & .bx-controls-auto {
    text-align: center;
    &.bx-start {
      display: block;
      text-indent: -9999px;
      width: 10px;
      height: 11px;
      outline: 0;
      background: url("./images/common/controls.png") -86px -11px no-repeat;
      margin: 0 3px;
      &:hover,
      &.active,
      &:focus {
        background-position: -86px 0;
      }
    }
  }
  & .bx-controls-auto .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    outline: 0;
    background: url("./images/common/controls.png") -86px -44px no-repeat;
    margin: 0 3px;
    &:hover,
    &.active,
    &:focus {
      background-position: -86px -33px;
    }
  }
  /* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
  & .bx-controls.bx-has-controls-auto.bx-has-pager {
    &.bx-pager {
      text-align: left;
      width: 80%;
    }
    &.bx-controls-auto {
      right: 0;
      width: 35px;
    }
  }
  /* IMAGE CAPTIONS */
  & .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #666;
    background: rgba(80, 80, 80, 0.75);
    width: 100%;
    & span {
      color: #fff;
      font-family: Arial;
      display: block;
      font-size: 0.85em;
      padding: 10px;
    }
  }
}
