.post {
  margin-bottom: 2rem;
  padding: 1rem 0.5rem;
  background: $bg_postColor;
  &__title {
    margin-bottom: 1rem;
    @include fontsize(20);
    font-weight: bold;
    @include min-screen($breakpoint_pc) {
      @include fontsize(30);
    }
  }
  &-info {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    &__item {
      @include fontsize(12);
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
      &--category {
        &:before {
          content: "\f07b";
          color: $sub_color;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
      }
      &--date {
        &:before {
          content: "\f017";
          font-family: "Font Awesome 5 Free";
        }
      }
      &--modified {
        &:before {
          content: "\f2f1";
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
        }
      }
      & a {
        color: $sub_color;
        text-decoration: none;
      }
    }
  }
  &__content {
    margin-bottom: 2rem;
    & p {
      margin-bottom: 2rem;
      line-height: 2;
      @include min-screen($breakpoint_pc) {
        padding: {
          right: 0.5rem;
          left: 0.5rem;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // 記事内タイトル装飾
    & h2 {
      background: #333;
      border-left: solid 8px $key_color;
      color: #fefefe;
      margin-bottom: 1rem;
      padding: 0.6rem 1rem;
      @include fontsize(18);
      font-weight: bold;
    }
    & h3 {
      margin-bottom: 0.6rem;
      padding: 0.6em;
      color: $main_color;
      @include fontsize(18);
      font-weight: bold;
      border-left: solid 0.5rem $key_color;
    }
  }
}
.no-post {
  padding-left: 0.2rem;
}
////////////////////
// 本文装飾
////////////////////
strong {
  font-weight: bold;
}
.understand {
  position: relative;
  width: 98%;
  margin: 4rem auto;
  padding: 1.25rem 0.5rem 1rem;
  border: solid 2px $sub_color;
  border-radius: 0.25rem;
  & dt {
    position: absolute;
    top: -18px;
    left: 2%;
    padding: 0.25rem 0.5rem;
    background: $sub_color;
    color: $bg_postColor;
    font-weight: bold;
    &::before {
      display: inline-block;
      content: "\f071";
      margin-right: 0.25rem;
      color: $bg_postColor;
      font: {
        family: "Font Awesome 5 Free";
        weight: bold;
      }
    }
  }
  & dd {
    line-height: 2;
  }
}
// 会話パート
.talk {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
  @include min-screen($breakpoint_pc) {
    width: 80%;
    margin: {
      right: auto;
      left: auto;
    }
  }
  &--left {
    & .talk__icon {
      order: 1;
      margin-right: 4%;
    }
    & .talk__text {
      order: 2;
      &::before {
        content: "";
        left: -12px;
        border: {
          top: solid 10px transparent;
          right: solid 10px #e0e0e0;
          bottom: solid 10px transparent;
        }
      }
    }
  }
  &--right {
    & .talk__icon {
      order: 2;
      margin-left: 4%;
    }
    & .talk__text {
      order: 1;
      &::before {
        content: "";
        right: -12px;
        border: {
          top: solid 10px transparent;
          bottom: solid 10px transparent;
          left: solid 10px #e0e0e0;
        }
      }
    }
  }
  &__icon {
    width: 20%;
    @include min-screen($breakpoint_pc) {
      max-width: 15%;
    }
    & img {
      border-radius: 50%;
    }
    & figcaption {
      @include fontsize(12);
      text-align: center;
      line-height: 1;
    }
  }
  &__text {
    position: relative;
    width: 76%;
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: solid 4px #e0e0e0;
    border-radius: 0.5rem;
    @include min-screen($breakpoint_pc) {
      max-width: 81%;
      margin-top: 0;
      padding: 1rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      width: 0;
      height: 0;
      @include min-screen($breakpoint_pc) {
        top: 2rem;
      }
    }
  }
}
// マーカーアニメーション
.marker {
  background: {
    image: linear-gradient(to left, transparent 50%, rgb(255, 250, 153) 50%);
    repeat: repeat-x;
    size: 200% 0.8em;
    position: 100% 0.5em;
  }
  font-weight: bold;
  transition: 1.5s;
  &.active {
    background-position: 0% 0.5em;
  }
}
// チェックリスト
.check-list {
  width: 98%;
  margin: 4rem auto;
  padding: 1rem;
  border: solid 2px $main_color;
  border-radius: 0.25rem;
  & li {
    margin-bottom: 0.5rem;
    &::before {
      content: "\f14a";
      display: inline-block;
      margin-right: 0.25rem;
      font: {
        family: "Font Awesome 5 Free";
        weight: bold;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
// 概要
.summary-list {
  width: 100%;
  margin: 2rem 0;
  padding: {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 2rem;
  }
  background: rgba($sub_color, 0.1);
  border: dotted 1px $sub_color;
  list-style: disc;
  & li {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
      @include min-screen($breakpoint_pc) {
        margin-bottom: 1rem;
      }
    }
  }
}
// 関連記事
.relation {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
  border: solid 1px #e3e3e3;
  border-radius: 0 1rem 0 1rem;
  text-decoration: none;
  @include min-screen($breakpoint_pc) {
    width: 80%;
    margin: {
      right: auto;
      left: auto;
    }
  }
  &:hover {
    @include min-screen($breakpoint_pc) {
      opacity: 0.7;
    }
  }
  & a {
    position: relative;
    display: block;
    height: 100%;
    padding: 0.5rem;
    color: $text_color;
    text-decoration: none;
  }
  &__eyecatch {
    position: relative;
    width: auto;
    & img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.25rem 0.5rem;
    background: $text_color;
    color: $bg_color;
    @include fontsize(12);
  }
  &__title {
    flex-grow: 1;
    color: $text_color;
    font-weight: bold;
  }
}
// sns share
.share {
  &__title {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0.25rem;
    @include min-screen($breakpoint_pc) {
      width: 60%;
      margin: {
        right: auto;
        left: auto;
      }
    }
    &::before,
    &::after {
      content: "";
      flex-grow: 1;
      height: 1px;
      background: $text_color;
    }
    &::before {
      margin-right: 0.5rem;
    }
    &::after {
      margin-left: 0.5rem;
    }
  }
}
.sns-item {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
  & a {
    width: 100%;
    color: #fff;
    text-align: center;
    &:hover {
      opacity: 0.6;
    }
    & i {
      @include fontsize(26);
      line-height: 1.5;
    }
  }
  &__twitter {
    background: #55acee;
  }
  &__facebook {
    background: #3b5998;
  }
  &__pocket {
    background: #ef3f56;
  }
  &__line {
    background: #1dcd00;
  }
  &__hatena {
    background: #00a5de;
  }
}
// 目次
#toc_container {
  & li {
    padding: {
      top: 0.25rem !important;
      bottom: 0.25rem !important;
    }
  }
}
