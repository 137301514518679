.footer {
  padding: {
    top: 1rem;
    bottom: 1rem;
  }
  background: $main_color;
  &__copyright {
    text-align: center;
    & small,
    & a {
      @include fontsize(12);
      color: $bg_color;
    }
    & a {
      text-decoration: none;
    }
  }
}
