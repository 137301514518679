.side-area {
  width: 100%;
  margin: {
    right: auto;
    bottom: 2rem;
    left: auto;
  }
  background: $bg_postColor;
  &__title {
    padding: 0.6rem 1rem;
    background: $main_color;
    color: #fefefe;
    border-left: solid 8px $key_color;
  }
}
.author {
  padding: 1rem 0.5rem;
  @include min-screen($breakpoint_pc) {
    padding: {
      right: 1rem;
      left: 1rem;
    }
  }
  &__img {
    width: 120px;
    height: auto;
    margin: {
      right: auto;
      left: auto;
    }
    & img {
      border-radius: 50%;
    }
  }
  &__name {
    margin-bottom: 1rem;
    @include fontsize(20);
    font-weight: bold;
    text-align: center;
  }
  &__comment {
    margin-bottom: 2rem;
    line-height: 1.6;
    & p {
      margin-bottom: 1rem;
      padding: {
        right: 0.4;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__sns {
    display: flex;
    justify-content: center;
    align-items: center;
    &-item {
      margin-right: 1rem;
      background: $main_color;
      border-radius: 50%;
      &::after {
        margin-right: 0;
      }
    }
    &-link {
      display: inline-block;
      width: 42px;
      text-align: center;
      border-radius: 50%;
      & i {
        color: #fff;
        @include fontsize(22);
        line-height: 42px;
      }
    }
  }
}
.timeline {
  width: $wrap_sp;
  margin: 0 auto;
  text-align: center;
}
////////////////////
// カテゴリー
////////////////////
.cat-item {
  padding: {
    right: 0.6rem;
    left: 0.6rem;
  }
  &:before {
    display: inline-block;
    content: "\f07b";
    margin-right: 0.2rem;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
  }
  & a {
    display: inline-block;
    padding: {
      top: 0.4rem;
      bottom: 0.4rem;
    }
    color: #333;
    text-decoration: none;
  }
  & .children {
    & .cat-item {
      &:before {
        @extend .cat-item:before;
        color: $sub_color;
      }
    }
    & > .cat-item > a {
      color: $sub_color;
    }
  }
}
////////////////////
// popurar
////////////////////
.wpp-list {
  & .post-rank {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 1rem;
    padding: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
    & a {
      width: 30%;
      color: $main_color;
      text-decoration: none;
      &:first-child {
        position: relative;
        &:after {
          counter-increment: post_rank;
          content: counter(post_rank);
          display: block;
          position: absolute;
          left: 0;
          width: 30px;
          height: 30px;
          background: rgba($key_color, 0.8);
          color: white;
          text-align: center;
          border-radius: 2px;
          line-height: 30px;
        }
      }
      &:last-child {
        width: 70%;
        padding: {
          right: 0.5rem;
          bottom: 0.5rem;
          left: 0.5rem;
        }
        @include fontsize(14);
        font-weight: bold;
      }
    }
  }
}
