.pagination {
  margin-bottom: 2rem;
  & ul {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    & li {
      margin: {
        right: 0.25rem;
        left: 0.25rem;
      }
      & .page-numbers {
        display: block;
        width: 100%;
        padding: 0.3rem 0.8rem;
        color: $main_color;
        text-decoration: none;
      }
      & .current {
        @extend .page-numbers;
        background: $main_color;
        color: $bg_color;
      }
    }
  }
}
